import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import {
  reset,
  updateGroup,
} from "../../redux/CreateBatchandGroup/CreateBatchGroup";
import Styles from "./_admin-login.module.css";
import Select from "react-select";
import Creatable from "react-select/creatable";

const UpdateGroup = () => {
  let { Success } = useSelector(state => state.auth);
  //(Success)
  let { groupCode } = useParams();

  let [trainers, setTrainers] = useState([]);
  let [Jtrainers, setJtrainers] = useState([]);
  // let [trackers, setTrackers] = useState([]);
  let [courses, setCourses] = useState([]);
  let [subjects, setSubjects] = useState([]);
  let [branches, setBranches] = useState([]);

  let [batchcode, setBatchCode] = useState("");
  let [purpose, setPurpose] = useState("");
  let [course, setCourse] = useState("");
  let [branch, setBranch] = useState("");

  let [trainer, setTrainer] = useState("");
  let [jounior, setJounior] = useState([]);
  let [juniorTrainer, setJuniorTrainer] = useState([]);
  let [addStudents, setAddStudents] = useState(Array);
  let [errors, setErrors] = useState({});

  let dispatch = useDispatch();
  let { isError, isSuccess, message } = useSelector(
    state => state.createBatchgroup
  );
  let navigate = useNavigate();
  let { userAdmin } = useSelector(state => state.auth);

  useEffect(() => {
    async function fetchlist() {
      AxiosInstance.get(
        `admin/groupList/groupdata/${encodeURIComponent(groupCode)}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      ).then(data => {
        //(data)
        //(data)
        //(data);
        let batch = data?.data?.groupData?.groupCode;
        let subje = data?.data?.groupData?.purpose;
        let cour = data?.data?.groupData?.course;
        let bran = data?.data?.groupData?.branch;
        let train = data?.data?.groupData?.trainer;
        let junior = data?.data?.groupData?.juniorTrainer;

        setBatchCode(batch);
        setPurpose(subje);
        setCourse(cour);
        setBranch(bran);
        setTrainer(train);

        let bbb = junior.map(function (ele) {
          return ele.username;
        });
        //(bbb)
        setJounior(bbb);
      });
    }
    fetchlist();
  }, []);

  useEffect(() => {
    async function fetchData() {
      // get Subjects
      let Subjectdata = await AxiosInstance.get(`auth/getdata?data=purpose`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
      // //(Subjectdata);
      let Subjectdataaa = Subjectdata?.data[0].purpose;
      // //(Subjectdataaa);
      let ddd = Subjectdataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setSubjects(ddd);
    }
    fetchData();
  }, [userAdmin._id]);
  useEffect(() => {
    async function fetchData() {
      // // get Course
      let Coursedata = await AxiosInstance.get(`auth/getdata?data=course`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
      // //(Coursedata);
      let Coursedataaa = Coursedata?.data[0].course;
      // //(Coursedataaa);
      let eee = Coursedataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setCourses(eee);
    }
    fetchData();
  }, [userAdmin._id]);

  useEffect(() => {
    async function fetchData() {
      // // get branch
      let Branchdata = await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
      // //(Branchdata)
      let Branchdataaa = Branchdata?.data[0]?.branch;
      // //(Branchdataaa)
      let ccc = Branchdataaa?.map(function (ele) {
        return { value: ele, label: ele };
      });
      setBranches(ccc);
    }
    fetchData();
  }, [userAdmin._id]);

  useEffect(() => {
    async function fetchData() {
      let trainerdata = await AxiosInstance.get(
        `auth/getusersrole?role=trainer`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      // //(trainerdata);
      let trainersss = trainerdata?.data?.usersDetails;
      let aaa = trainersss.map(function (ele) {
        return { value: ele.username, label: ele.username };
      });
      let bbb = trainersss.map(function (ele) {
        return { value: ele, label: ele.username };
      });
      setTrainers(aaa);
      setJtrainers(bbb);
    }
    fetchData();
  }, [userAdmin._id, Success === true]);

  useEffect(() => {
    if (isError) {
      toast.error(message.message, {
        position: "top-right",
      });
      dispatch(reset());
    }
    if (isSuccess) {
      //("group updated")
      toast.success("Group added successfully", { position: "top-right" });
      dispatch(reset());
      navigate("/admin-dashboard/admin/groupList");
    }
    // //(isSuccess);
  }, [isSuccess, isError]);

  let onChangeselect = e => {
    if (e?.value) {
      setPurpose(e.value);
    } else {
      setPurpose("");
    }
  };
  let ChangeCourse = e => {
    if (e?.value) {
      setCourse(e.value);
    } else {
      setCourse("");
    }
  };
  const handleChange = e => {
    if (e?.value) {
      setTrainer(e.value);
    } else {
      setTrainer("");
    }
  };
  const handleChangeBranch = e => {
    if (e?.value) {
      //(e.value);
      setBranch(e.value);
    } else {
      setBranch("");
    }
  };

  let handleSubmit = e => {
    e.preventDefault();
    try {
      let form = new FormData();
      form.append("file", addStudents);
      form.append("groupCode", groupCode);
      form.append("purpose", purpose);
      form.append("course", course);
      form.append("branch", branch);
      form.append("trainer", trainer);
      form.append("juniorTrainer", JSON.stringify(juniorTrainer));
      // //(form)
      // form.append("tracker", tracker);
      dispatch(updateGroup(form));
      // dispatch(reset());
    } catch (error) {}
    // }
  };

  //(purpose);
  return (
    <section id={Styles.createBatch}>
      <article>
        <div className={Styles.container}>
          <h1>Update Group</h1>
          <form onSubmit={handleSubmit} className={Styles.createBatchForm}>
            <div>
              <div className={Styles.formgroup}>
                <label htmlFor="groupcode">GroupCode</label>
                <input
                  className="form-control"
                  type="text"
                  name="groupcode"
                  // required
                  readOnly
                  value={batchcode}
                  placeholder="Enter Groupcode"
                  onChange={e => setBatchCode(e.target.value)}
                />
                {errors.batchcode && (
                  <p className={Styles.errors}>{errors.batchcode}</p>
                )}
              </div>
              <div className={Styles.trainer1}>
                <label htmlFor="purpose">Pupose</label>

                <Select
                  isClearable
                  isSearchable
                  placeholder={purpose}
                  escapeClearsValue={true}
                  labelKey="name"
                  className={Styles.option}
                  options={subjects}
                  onChange={onChangeselect}
                />
                <p
                  className={
                    errors.purpose ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>

                {errors.purpose && (
                  <p className={Styles.errors}>{errors.purpose}</p>
                )}
              </div>
              <div className={Styles.trainer1}>
                <label htmlFor="course">course</label>

                <Creatable
                  placeholder={course}
                  isClearable
                  className={Styles.option}
                  options={courses}
                  onChange={ChangeCourse}
                />
                <p
                  className={
                    errors.course ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.course && (
                  <p className={Styles.errors}>{errors.course}</p>
                )}
              </div>
              <div className={Styles.trainer1}>
                <label htmlFor="branch">branch</label>

                <Creatable
                  placeholder={branch}
                  className={Styles.option}
                  options={branches}
                  onChange={handleChangeBranch}
                />
                <p
                  className={
                    errors.branch ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>

                {errors.branch && (
                  <p className={Styles.errors}>{errors.branch}</p>
                )}
              </div>
              {/* ----------------------trainer------------------------------- */}
              <div className={Styles.trainer}>
                <label htmlFor="trainer">Trainer </label>

                <Creatable
                  placeholder={trainer}
                  isClearable
                  options={trainers}
                  onChange={handleChange}
                  labelKey="name"
                />
                <p
                  className={
                    errors.trainer ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.trainer && (
                  <p className={Styles.errors}>{errors.trainer}</p>
                )}
              </div>
              {/* --------------------------juniour trainer -------------------------*/}

              <div className={Styles.trainer}>
                <label htmlFor="juniorTrainer">Junior Trainer </label>

                <Select
                  placeholder={jounior.join(", ")}
                  closeMenuOnSelect={false}
                  isMulti
                  name="students"
                  options={Jtrainers}
                  onChange={e => {
                    setJuniorTrainer(
                      Array.isArray(e)
                        ? e.map(x => ({
                            _id: x.value._id,
                            username: x.value.username,
                          }))
                        : []
                    );
                  }}
                />
              </div>
              {/* <div className={Styles.formgroup}>
                <label>Upload Your File </label>
                <input
                  type="file"
                  id="addStudents"
                  name="addStudents"
                  onChange={e => setAddStudents(e.target.files[0])}
                />
                {errors.addStudents && (
                  <p className={Styles.errors}>{errors.addStudents}</p>
                )}
            
              </div> */}
            </div>
            <aside className={Styles.createButton}>
              <button>Update Group</button>
            </aside>
          </form>
        </div>
      </article>
    </section>
  );
};

export default UpdateGroup;
