import axios from "axios";

export default axios.create({
  // baseURL:"http://192.168.1.202:4545",
  // baseURL: "https://testchatb.qspiders.com",
  baseURL: "https://chatb.qspiders.com",

  // baseURL:"http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});

export let fileAxios = axios.create({
  // baseURL:"http://192.168.1.202:4545",
  // baseURL: "https://testchatb.qspiders.com",
  baseURL: "https://chatb.qspiders.com",

  // baseURL: "https://chatb.qspiders.com",
  // baseURL:"http://localhost:5000",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export let testing = axios.create({
  // baseURL: "http://192.168.1.202:4545",
  baseURL: "https://testchatb.qspiders.com",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export let BddtAxios = axios.create({
  // baseURL: "http://106.51.74.69:8787",
  baseURL: "https://bdbackend.qspiders.com",
  // baseURL: "https://hrstbackend.qspiders.com/",

  // baseURL:"http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});
export let BddtFile = axios.create({
  // baseURL: "http://106.51.74.69:8787",
  baseURL: "https://bdbackend.qspiders.com",
  // baseURL: "https://hrstbackend.qspiders.com/",

  // baseURL:"http://localhost:5000",
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer",
  },
});

export let VideoDemo = axios.create({
  // baseURL: "https://testdemovideob.qspiders.com/",
  baseURL: "https://wiki.qspiders.com/",

  headers: {
    "Content-Type": "application/json",
  },
});

export let Qandq = axios.create({
  // baseURL: "https://gotest.qspiders.com",

  headers: {
    "Content-Type": "application/json",
  },
});

export let GroomingUri = axios.create({
  // baseURL: "https://hrst.qspiders.com",
  baseURL: "https://studentcareer.qspiders.com",
  headers: {
    "Content-Type": "application/json",
  },
});
/// routing if grroming is assigned live ---- https://studentcareer.qspiders.com
/// routing if grroming is assigned testing ---- https://hrst.qspiders.com

export const imageUrl = `https://chatb.qspiders.com`;
// export const imageUrl = `https://testchatb.qspiders.com`;

// export const testFreshers = `https://testquiz.qspiders.com/`;
export const testFreshers = `https://testfreshers.qspiders.com/login`;

// export const videoApp = `https://testdemovideo.qspiders.com`;
export const videoApp = `https://wiki.qspiders.com`;

export let uploadResumeNewUrl = axios.create({
  baseURL: "https://gotest.qspiders.com/api/student/",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// export const qandq = `https://devstudent.qspiders.com/`;
export const qandq = `https://student.qspiders.com/`;
